import ShopSetup from '@/components/Views/Settings/ShopSetup/ShopSetup'

export default [
  {
    path: 'shop-setup',
    component: ShopSetup,
    name: 'shop_setup',
    meta: {
      title: 'Shop Setup',
    },
  },
  {
    path: 'general/site-settings',
    component: () => import('@/components/Views/Settings/General/SiteSettings'),
    name: 'site_settings',
    meta: {
      title: 'Site Settings',
    },
  },
  // {
  //   path: 'general/site-settings/website/new',
  //   component: () => import('@/components/Views/Settings/General/Website/Form'),
  //   name: 'system_website_new',
  //   meta: {
  //     title: 'Add New Website'
  //   }
  // },
  // {
  //   path: 'general/site-settings/website/edit/:id?',
  //   component: () => import('@/components/Views/Settings/General/Website/Form'),
  //   name: 'system_website_edit',
  //   meta: {
  //     title: 'Edit Website'
  //   }
  // },
  // {
  //   path: 'general/website-groups',
  //   component: () => import('@/components/Views/Settings/General/WebsiteGroups'),
  //   name: 'system_tag_manage',
  //   meta: {
  //     title: 'Website Groups'
  //   }
  // },
  {
    path: 'general/site-map',
    component: () => import('@/components/Views/Settings/General/Sitemap'),
    name: 'site_map',
    meta: {
      title: 'Sitemap'
    }
  },
  {
    path: 'general/robots-txt',
    component: () => import('@/components/Views/Settings/General/RobotsTxt'),
    name: 'robots_txt',
    meta: {
      title: 'Robots.txt',
    },
  },
  {
    path: 'general/search',
    component: () => import('@/components/Views/Settings/General/Search'),
    name: 'search_setting',
    meta: {
      title: 'Search',
    },
  },
  {
    path: 'general/login-options',
    component: () => import('@/components/Views/Settings/General/LoginOptions'),
    name: 'login_options',
    meta: {
      title: 'Login Options',
    },
  },
  {
    path: 'general/job-scheduler',
    component: () => import('@/components/Views/Settings/General/JobScheduler/Main'),
    name: 'setting_job_main',
    meta: {
      title: 'Job Scheduler'
    }
  },
  {
    path: 'general/job-scheduler/:id',
    component: () => import('@/components/Views/Settings/General/JobScheduler/Form'),
    name: 'setting_job_edit',
    meta: {
      title: 'Edit Job',
      type: 'edit'
    }
  },
  {
    path: 'general/site-redirects',
    component: () => import('@/components/Views/Settings/General/SiteRedirects/Main'),
    name: 'setting_redirect_manage',
    meta: {
      title: 'Site Redirects'
    }
  },
  {
    path: 'general/messaging',
    component: () => import('@/components/Views/Settings/General/Messaging'),
    name: 'setting_messaging',
    meta: {
      title: 'Messaging'
    }
  },
  {
    path: 'general/password-protection',
    component: () => import('@/components/Views/Settings/General/PasswordProtection'),
    name: 'setting_password_protection',
    meta: {
      title: 'Password Protection'
    }
  },
  /* NOTIFICATIONS */
  {
    path: 'notifications/admin-notifications',
    component: () => import('@/components/Views/Settings/Notifications/NotificationEvent/Main'),
    name: 'notification_events_admin',
    meta: {
      title: 'Admin Notifications Manager',
      type: 'admin'
    },
  },
  {
    path: 'notifications/customer-notifications',
    component: () => import('@/components/Views/Settings/Notifications/NotificationEvent/Main'),
    name: 'notification_events_customer',
    meta: {
      title: 'Customer Notifications Manager',
      type: 'customer'
    },
  },
  {
    path: 'notifications/customer-notifications/edit/:id',
    component: () => import('@/components/Views/Settings/Notifications/NotificationEvent/Edit'),
    name: 'notification_event_edit',
    meta: {
      title: 'Edit Notification Event',
    },
  },
  {
    path: 'notifications/pdf-quote-templates',
    component: () => import('@/components/Views/Settings/Notifications/PDFQuoteTemplates'),
    name: 'pdf_quote_templates',
    meta: {
      title: 'PDF Quote Templates',
    },
  },
  {
    path: 'general/site-redirects/new',
    component: () => import('@/components/Views/Settings/General/SiteRedirects/Edit'),
    name: 'setting_redirect_new',
    meta: {
      title: 'Add New Redirect'
    }
  },
  {
    path: 'general/site-redirects/:id',
    component: () => import('@/components/Views/Settings/General/SiteRedirects/Edit'),
    name: 'setting_redirect_edit',
    meta: {
      title: 'Edit Redirect',
      type: 'edit'
    }
  },
  {
    path: 'notifications/email-settings',
    component: () => import('@/components/Views/Settings/Notifications/EmailSetting'),
    name: 'setting_email-settings',
    meta: {
      title: 'Email Settings',
    }
  },
  {
    path: 'notifications/admin-notifications',
  },
  {
    path: 'notifications/customer-notifications',
  },
  // {
  //   path: 'notifications/opt-in',
  //   component: () => import('@/components/Views/Settings/General/OptIn'),
  //   name: 'setting_opt-in',
  //   meta: {
  //     title: 'Opt-In',
  //   }
  // },
  {
    path: 'orders/payment-providers',
    component: () => import('@/components/Views/Settings/Orders/PaymentProviders/index'),
    name: 'orders_payment_provider',
    meta: {
      title: 'Setting Payment Provider',
    },
  },
  {
    path: 'orders/fraud-protection-signifyd',
    component: () => import('@/components/Views/Settings/Orders/FraudProtectionSignifyd'),
    name: 'fraud_protection_signifyd',
    meta: {
      title: 'Setting Fraud Protection Signifyd',
    },
  },
  {
    path: 'orders/order-status-manager',
    component: () => import('@/components/Views/Sales/OrderStatus/Main'),
    name: 'orders_order_status_manager',
    meta: {
      title: 'Order Status Manager',
    },
  },
  {
    path: 'orders/order-status-new',
    component: () => import('@/components/Views/Sales/OrderStatus/Form'),
    name: 'orders_order_status_new',
    meta: {
      title: 'Create Order Status',
    },
  },
  {
    path: 'orders/order-status-edit/:id',
    component: () => import('@/components/Views/Sales/OrderStatus/Form'),
    name: 'orders_order_status_edit',
    meta: {
      title: 'Edit Order Status',
    },
  },
  {
    path: 'orders/order-rules',
    component: () => import('@/components/Views/Sales/OrderRule/Main'),
    name: 'orders_order_rules',
    meta: {
      title: 'Order Rules',
    },
  },
  {
    path: 'orders/order-rule-new',
    component: () => import('@/components/Views/Sales/OrderRule/Form'),
    name: 'orders_order_rule_new',
    meta: {
      title: 'Add Order Rule',
    },
  },
  {
    path: 'orders/order-rule-edit/:id',
    component: () => import('@/components/Views/Sales/OrderRule/Form'),
    name: 'orders_order_rule_edit',
    meta: {
      title: 'Edit Order Rule',
    },
  },
  {
    path: 'orders/fraud-rules',
    component: () => import('@/components/Views/Sales/FraudRule/Main'),
    name: 'orders_fraud_rules',
    meta: {
      title: 'Fraud Rules',
    },
  },
  {
    path: 'orders/fraud-rule-new',
    component: () => import('@/components/Views/Sales/FraudRule/Form'),
    name: 'orders_fraud_rule_new',
    meta: {
      title: 'Add Fraud Rule',
    },
  },
  {
    path: 'orders/fraud-rule-edit/:id',
    component: () => import('@/components/Views/Sales/FraudRule/Form'),
    name: 'orders_fraud_rule_edit',
    meta: {
      title: 'Edit Fraud Rule',
    },
  },
  {
    path: 'orders/order-defaults',
    component: () => import('@/components/Views/Settings/Orders/Defaults'),
    name: 'orders_defautls',
    meta: {
      title: 'Order Defaults',
    },
  },
  {
    path: 'orders/shopping-list',
    component: () => import('@/components/Views/Settings/Orders/ShoppingList'),
    name: 'orders_shopping_list',
    meta: {
      title: 'Order Shopping List',
    },
  },
  {
    path: 'orders/save-for-later',
    component: () => import('@/components/Views/Settings/Orders/SaveForLater'),
    name: 'orders_save_for_later',
    meta: {
      title: 'Order Save For Later',
    },
  },
  {
    path: 'orders/order-attributes',
    component: () => import('@/components/Views/Common/Attribute/Main'),
    name: 'order_attributes',
    meta: {
      title: 'Order Attributes',
      isRerenderComponent: true,
    }
  },
  {
    path: 'orders/pdf-invoice-templates',
    component: () => import('@/components/Views/Settings/Orders/PDFInvoiceTemplates'),
    name: 'pdf_invoice_templates',
    meta: {
      title: 'PDF Invoice Templates',
    },
  },
  {
    path: 'tax/tax-calulation-method',
    component: () => import('@/components/Views/Settings/Tax/TaxCalculationMethod'),
    name: 'tax_calulation_method',
    meta: {
      title: 'Tax Calculation Method',
    },
  },
  {
    path: 'tax/tax-rules',
    component: () => import('@/components/Views/Sales/Tax/Rule'),
    name: 'tax_rules',
    meta: {
      title: 'Tax Rules',
    },
  },
  {
    path: 'tax/rule/new',
    component: () => import('@/components/Views/Sales/Tax/Rule/Form'),
    name: 'tax_rule_new',
    meta: {
      title: 'Add New Tax Rule',
      type: 'new',
    },
  },
  {
    path: 'tax/rule/edit/:id',
    component: () => import('@/components/Views/Sales/Tax/Rule/Form'),
    name: 'tax_rule_edit',
    meta: {
      title: 'Edit Tax Rule',
      type: 'edit',
    },
  },
  {
    path: 'tax/tax-categories',
    component: () => import('@/components/Views/Sales/Tax/Category'),
    name: 'tax_category',
    meta: {
      title: 'Tax Categories',
    },
  },
  {
    path: 'tax/tax-rates',
    component: () => import('@/components/Views/Sales/Tax/Rate'),
    name: 'tax_rates',
    meta: {
      title: 'Tax Rates',
    },
  },
  {
    path: 'tax/rate/new',
    component: () => import('@/components/Views/Sales/Tax/Rate/Form'),
    name: 'tax_rate_new',
    meta: {
      title: 'Add New Tax Rate',
      type: 'new',
    },
  },
  {
    path: 'tax/rate/edit/:id',
    component: () => import('@/components/Views/Sales/Tax/Rate/Form'),
    name: 'tax_rate_edit',
    meta: {
      title: 'Edit Tax Rate',
      type: 'edit',
    },
  },
  {
    path: 'shipping/shipping-labels',
    component: () => import('@/components/Views/Settings/Shipping/LabelSoftware'),
    name: 'shipping_label-software',
    meta: {
      title: 'Shipping Labels'
    }
  },
  {
    path: 'shipping/rate-groups',
    component: () => import('@/components/Views/Shipping/RateGroup/Main'),
    name: 'rate_groups',
    meta: {
      title: 'Rate Groups'
    }
  },
  {
    path: 'shipping/package-types',
    component: () => import('@/components/Views/Shipping/PackageTypes/Main'),
    name: 'shipping_package_types',
    meta: {
      title: 'Package Types'
    }
  },
  {
    path: 'shipping/shipping-days',
    component: () => import('@/components/Views/Settings/Shipping/ShippingDays'),
    name: 'shipping_shipping-days',
    meta: {
      title: 'Shipping Days'
    }
  },
  {
    path: 'shipping/shipping-options',
    component: () => import('@/components/Views/Shipping/ShippingOptions/Main'),
    name: 'shipping_shipping_options',
    meta: {
      title: 'Shipping Options'
    }
  },
  {
    path: 'shipping/marketplace-shipping',
    component: () => import('@/components/Views/Shipping/MarketplaceShipping/Main'),
    name: 'shipping_marketplace_shipping',
    meta: {
      title: 'Marketplace Shipping'
    }
  },
  {
    path: 'shipping/delivery-estimates',
    component: () => import('@/components/Views/Shipping/DeliveryEstimate/Main'),
    name: 'shipping_delivery_estimates',
    meta: {
      title: 'Delivery Estimates'
    }
  },
  {
    path: 'shipping/shipping-location',
    component: () => import('@/components/Views/Shipping/Warehouse/Index'),
    name: 'shipping_location',
    meta: {
      title: 'Shipping Location'
    }
  },
  {
    path: 'shipping/shipping-location/new',
    component: () => import('@/components/Views/Shipping/Warehouse/Form'),
    name: 'shipping_location_new',
    meta: {
      title: 'Add a Warehouse'
    }
  },
  {
    path: 'shipping/shipping-location/:id',
    component: () => import('@/components/Views/Shipping/Warehouse/Form'),
    name: 'shipping_location_edit',
    meta: {
      title: 'Edit a Warehouse'
    }
  },
  {
    path: 'shipping/carrier-management',
    component: () => import('@/components/Views/Settings/Shipping/CarrierManagement'),
    name: 'shipping_carrier_management',
    meta: {
      title: 'Carrier Management'
    }
  },
  {
    path: 'shipping/allowed-countires',
    component: () => import('@/components/Views/Settings/Shipping/AllowedCountries'),
    name: 'shipping_allowed_countires',
    meta: {
      title: 'Allowed Countries'
    }
  },
  {
    path: 'shipping/packing-slip-template',
    component: () => import('@/components/Views/Settings/Shipping/PackingSlipTemplate'),
    name: 'packing_slip_template',
    meta: {
      title: 'Packing Slip Template'
    }
  },
  /* PRODUCTS */
  {
    path: 'products/product-attributes',
    component: () => import('@/components/Views/Common/Attribute/Main'),
    name: 'product_attributes',
    meta: {
      title: 'Manage Attributes',
      isRerenderComponent: true,
    }
  },
  {
    path: 'attributes/new',
    component: () => import('@/components/Views/Common/Attribute/Edit'),
    name: 'system_attributes_new',
    meta: {
      title: 'Add new Attribute'
    }
  },
  {
    path: 'attributes/edit/:id',
    component: () => import('@/components/Views/Common/Attribute/Edit'),
    name: 'system_attributes_edit',
    meta: {
      title: 'Edit Attribute',
    }
  },
  {
    path: 'products/product-attribute-presets',
    component: () => import('@/components/Views/Common/Attribute/Preset/Main'),
    name: 'product_attribute_presets',
    meta: {
      title: 'Product Attribute Presets Manager',
      entity_code: 'product',
      isRerenderComponent: true,
    },
  },
  {
    path: 'attribute-preset/new/:entity_code?/:class_code?',
    component: () => import('@/components/Views/Common/Attribute/Preset/Edit'),
    name: 'catalog_preset_new',
    meta: {
      title: 'Creating a Preset',
      entity_code: 'product',
      type: 'new',
    },
  },
  {
    path: 'attribute-preset/edit/:id',
    component: () => import('@/components/Views/Common/Attribute/Preset/Edit'),
    name: 'catalog_preset_edit',
    meta: {
      title: 'Editing a Preset',
      entity_code: 'product',
      type: 'edit',
    },
  },
  {
    path: 'products/product-types',
    component: () => import('@/components/Views/Common/EntityType/Main'),
    name: 'product_type_manager',
    meta: {
      title: 'Product Types Manager',
      entity: { code: 'product', name: 'Product' },
      isRerenderComponent: true,
    },
  },
  {
    path: 'products/product-types/new',
    component: () => import('@/components/Views/Common/EntityType/Form'),
    name: 'product_type_new',
    meta: {
      title: 'New Product Type',
      entity: { code: 'product', name: 'Product' },
    }
  },
  {
    path: 'products/product-types/edit/:id',
    component: () => import('@/components/Views/Common/EntityType/Form'),
    name: 'product_type_edit',
    meta: {
      title: 'Edit Product Type',
      entity: { code: 'product', name: 'Product' },
    }
  },
  {
    path: 'products/variant-templates',
    component: () => import('@/components/Views/Settings/Products/VariantTemplate/VariantTemplates'),
    name: 'catalog_product_variant_template',
    meta: {
      title: 'Variant Templates',
    },
  },
  {
    path: 'products/options',
    component: () => import('@/components/Views/Catalog/Product/ProductOptions/Main'),
    name: 'catalog_product_options',
    meta: {
      title: 'Product Options Manager',
    },
  },
  {
    path: 'products/options/new',
    component: () => import('@/components/Views/Catalog/Product/ProductOptions/Form'),
    name: 'catalog_product_options_new',
    meta: {
      title: 'Add New Product Option',
    },
  },
  {
    path: 'products/options/edit/:id',
    component: () => import('@/components/Views/Catalog/Product/ProductOptions/Form'),
    name: 'catalog_product_options_edit',
    meta: {
      title: 'Edit Product Option',
    },
  },
  /*  */
  {
    path: 'products/option-sets',
    component: () => import('@/components/Views/Catalog/Product/ProductOptionSets/Main'),
    name: 'catalog_product_option_sets',
    meta: {
      title: 'Option Sets Manager',
    },
  },
  {
    path: 'products/option-sets/new',
    component: () => import('@/components/Views/Catalog/Product/ProductOptionSets/Form'),
    name: 'catalog_product_option_sets_new',
    meta: {
      title: 'Add New Product Option Set',
    },
  },
  {
    path: 'products/option-sets/edit/:id',
    component: () => import('@/components/Views/Catalog/Product/ProductOptionSets/Form'),
    name: 'catalog_product_option_sets_edit',
    meta: {
      title: 'Edit Product Option Set',
    },
  },
  {
    path: 'products/product-customizer-template-list',
    component: () => import('@/components/Views/Settings/Products/ProductCustomizer/TemplateList'),
    name: 'product_customizer_template_list',
    meta: {
      title: 'Product Customizer'
    }
  },
  {
    path: 'products/map-setting',
    component: () => import('@/components/Views/Settings/Products/MAPSetting'),
    name: 'products_map_setting',
    meta: {
      title: 'MAP Setting',
    },
  },
  {
    path: 'products/inventory-defaults',
    component: () => import('@/components/Views/Settings/Products/InventoryDefaults'),
    name: 'products_inventory_defaults',
    meta: {
      title: 'Inventory Defaults',
    },
  },
  {
    path: 'products/product-reviews',
    component: () => import('@/components/Views/Settings/Products/ProductReviews'),
    name: 'products_product_reviews',
    meta: {
      title: 'Product Reviews',
    },
  },
  {
    path: 'products/api-log',
    component: () => import('@/components/Views/Common/APILog/Main'),
    name: 'api_log_manager',
    meta: {
      title: 'API Logs',
    },
  },
  {
    path: 'products/placeholder-image',
    component: () => import('@/components/Views/Settings/Products/PlaceholderImage'),
    name: 'product_placeholder_image',
    meta: {
      title: 'Placeholder Image',
    },
  },
  {
    path: 'products/sku-defaults',
    component: () => import('@/components/Views/Settings/Products/SkuDefaults'),
    name: 'product_sku_defaults',
    meta: {
      title: 'SKU Defaults',
    },
  },
  /* CUSTOMERS */
  {
    path: 'customers/customer-attributes',
    component: () => import('@/components/Views/Common/Attribute/Main'),
    name: 'customer_attributes',
    meta: {
      title: 'Customer Attributes',
      isRerenderComponent: true,
    }
  },
  /* CATEGORIES */
  {
    path: 'categories/category-attributes',
    component: () => import('@/components/Views/Common/Attribute/Main'),
    name: 'category_attributes',
    meta: {
      title: 'Manage Attributes',
      isRerenderComponent: true,
    }
  },
  {
    path: 'categories/category-attribute-presets',
    component: () => import('@/components/Views/Common/Attribute/Preset/Main'),
    name: 'category_attribute_presets',
    meta: {
      title: 'Category Attribute Presets',
      entity_code: 'category',
      isRerenderComponent: true,
    },
  },
  {
    path: 'categories/category-types',
    component: () => import('@/components/Views/Common/EntityType/Main'),
    name: 'category_type_manager',
    meta: {
      title: 'Category Types Manager',
      entity: { code: 'category', name: 'Category' },
      isRerenderComponent: true,
    },
  },
  {
    path: 'categories/category-types/new',
    component: () => import('@/components/Views/Common/EntityType/Form'),
    name: 'category_type_new',
    meta: {
      title: 'New Category Type',
      entity: { code: 'category', name: 'Category' },
    }
  },
  {
    path: 'categories/category-types/edit/:id',
    component: () => import('@/components/Views/Common/EntityType/Form'),
    name: 'category_type_edit',
    meta: {
      title: 'Edit Category Type',
      entity: { code: 'category', name: 'Category' },
    }
  },
  /* CONTENT */
  {
    path: 'content/headers',
    component: () => import('@/components/Views/Content/Header/Main'),
    name: 'headers',
    meta: {
      title: 'Headers',
    },
  },
  {
    path: 'content/footer',
    component: () => import('@/components/Views/Content/Footer/Main'),
    name: 'footer',
    meta: {
      title: 'Footer',
    },
  },
  {
    path: 'content/page-attributes',
    component: () => import('@/components/Views/Common/Attribute/Main'),
    name: 'page_attributes',
    meta: {
      title: 'Manage Attributes',
      isRerenderComponent: true,
    }
  },
  {
    path: 'content/page-attribute-presets',
    component: () => import('@/components/Views/Common/Attribute/Preset/Main'),
    name: 'page_attribute_presets',
    meta: {
      title: 'Page Attribute Presets Manager',
      entity_code: 'page',
      isRerenderComponent: true,
    },
  },

  {
    path: 'content/banner-generator',
    component: () => import('@/components/Views/ContentGenerator/Main'),
    meta: {
      title: 'Banner Generator',
    },
  },
  {
    path: 'content/page-mapping',
    component: () => import('@/components/Views/Settings/Content/PageMapping'),
    name: 'page_mapping',
    meta: {
      title: 'Page Mapping',
    },
  },
  {
    path: 'content/site-logos',
    component: () => import('@/components/Views/Settings/Content/SiteLogos'),
    name: 'site_logos',
    meta: {
      title: 'Site Logos',
    },
  },
  {
    path: 'theme/font-family-colors',
    component: () => import('@/components/Views/Settings/Theme/FontFamilyAndColors'),
    name: 'font_family_colors',
    meta: {
      title: 'Font Family & Colors',
    },
  },
  /**
   *  Note:additional scripts moved to content>header and content>footer
   * */
  // {
  //   path: 'content/additional-scripts',
  //   component: () => import('@/components/Views/Settings/Content/AdditionalScripts'),
  //   name: 'additional_scripts',
  //   meta: {
  //     title: 'Additional Scripts',
  //   },
  // },
  {
    path: 'content/global-metatags',
    component: () => import('@/components/Views/Settings/Content/GlobalMetatags'),
    name: 'global_metatags',
    meta: {
      title: 'Global Metatags',
    },
  },
  {
    path: 'attribute-preset/new',
    component: () => import('@/components/Views/Common/Attribute/Preset/Edit'),
    name: 'content_page_preset_new',
    meta: {
      title: 'Creating a Preset',
      entity_code: 'page',
      type: 'new'
    }
  },
  {
    path: 'attribute-preset/edit/:id',
    component: () => import('@/components/Views/Common/Attribute/Preset/Edit'),
    name: 'content_page_preset_edit',
    meta: {
      title: 'Editing a Preset',
      entity_code: 'page',
      type: 'edit'
    }
  },
  {
    path: 'content/page-types',
    component: () => import('@/components/Views/Common/EntityType/Main'),
    name: 'page_types_manager',
    meta: {
      title: 'Page Types Manager',
      entity: { code: 'page', name: 'Page' },
      isRerenderComponent: true,
    },
  },
  {
    path: 'content/site-design',
    component: () => import('@/components/Views/Settings/Content/SiteDesign'),
    name: 'site_design',
    meta: {
      title: 'Site Design'
    }
  },
  {
    path: 'content/site-messaging',
    component: () => import('@/components/Views/Settings/Content/SiteMessaging'),
    name: 'site_messaging',
    meta: {
      title: 'Site Messaging'
    }
  },
  {
    path: 'content/term-and-privacy',
    component: () => import('@/components/Views/Settings/Content/TermAndPrivacy'),
    name: 'term_and_privacy',
    meta: {
      title: 'Store Terms and Privacy'
    }
  },
  /* INTEGRATION */
  {
    path: 'integration/quick-books-queue',
    component: () => import('@/components/Views/Settings/Integration/QuickBooksQueue'),
    name: 'quickbooks_queue',
    meta: {
      title: 'QuickBooks Queue',
    },
  },
  {
    path: 'integration/quick-books-log',
    component: () => import('@/components/Views/Settings/Integration/QuickBooksLog'),
    name: 'quickbooks_log',
    meta: {
      title: 'QuickBooks Log',
    },
  },
  {
    path: 'integration/google',
    component: () => import('@/components/Views/Settings/Integration/Google'),
    name: 'integration_google',
    meta: {
      title: 'Google',
    },
  },
  {
    path: 'integration/add-this',
    component: () => import('@/components/Views/Settings/Integration/AddThis'),
    name: 'integration_add_this',
    meta: {
      title: 'AddThis',
    },
  },
  {
    path: 'integration/cart-rover',
    component: () => import('@/components/Views/Settings/Integration/CartRover'),
    name: 'integration_cart_rover',
    meta: {
      title: 'CartRover',
    },
  },
  {
    path: 'integration/novita',
    component: () => import('@/components/Views/Settings/Integration/Novita'),
    name: 'integration_novita',
    meta: {
      title: 'Novita',
    },
  },
  {
    path: 'integration/etsy',
    component: () => import('@/components/Views/Settings/Integration/Etsy'),
    name: 'integration_iomnic',
    meta: {
      title: 'iOmniC Settings',
    },
  },
  {
    path: 'integration/etsy-connector',
    component: () => import('@/components/Views/Settings/Integration/EtsyConnector'),
    name: 'integration_etsy_connector',
    meta: {
      title: 'Etsy Settings',
    },
  },
  {
    path: 'integration/quickbooks',
    component: () => import('@/components/Views/Settings/Integration/QuickBooks'),
    name: 'integration_quickbooks_connector',
    meta: {
      title: 'QuickBooks Connector Settings',
    },
  },
  {
    path: 'integration/klaviyo',
    component: () => import('@/components/Views/Settings/Integration/Klaviyo'),
    name: 'integration_klaviyo_connector',
    meta: {
      title: 'Klaviyo Settings',
    },
  },
  /* PERMISSIONS */
  {
    path: 'permissions/users',
    component: () => import('@/components/Views/Settings/Permission/User/Main'),
    name: 'system_user_main',
    meta: {
      title: 'Users Manager'
    }
  },
  {
    path: 'permissions/users/new',
    component: () => import('@/components/Views/Settings/Permission/User/Form'),
    name: 'system_user_new',
    meta: {
      title: 'New Admin User'
    }
  },
  {
    path: 'permissions/users/sync',
    component: () => import('@/components/Views/Settings/Permission/User/SyncUsers'),
    name: 'system_user_sync',
    meta: {
      title: 'Sync users'
    }
  },
  {
    path: 'permissions/users/edit/:id',
    component: () => import('@/components/Views/Settings/Permission/User/Form'),
    name: 'system_user_edit',
    meta: {
      title: 'Edit Admin User'
    }
  },
  {
    path: 'permissions/user-roles',
    component: () => import('@/components/Views/Settings/Permission/UserRoles/Main'),
    name: 'system_role_main',
    meta: {
      title: 'User Roles Manager'
    }
  },
  {
    path: 'permissions/user-roles/new',
    component: () => import('@/components/Views/Settings/Permission/UserRoles/Form'),
    name: 'system_role_new',
    meta: {
      type: 'new',
      title: 'New Role'
    }
  },
  {
    path: 'permissions/user-roles/:id',
    component: () => import('@/components/Views/Settings/Permission/UserRoles/Form'),
    name: 'system_role_edit',
    meta: {
      type: 'edit',
      title: 'Edit Role'
    }
  },
  {
    path: 'permissions/user-activity',
    component: () => import('@/components/Views/Settings/Permission/UserActivity'),
    name: 'system_user_activity',
    meta: {
      title: 'User Activity'
    }
  },
  {
    path: 'permissions/user-invites',
    component: () => import('@/components/Views/Settings/Permission/UserInvites'),
    name: 'system_user_invites',
    meta: {
      title: 'User Invites'
    }
  },
]
