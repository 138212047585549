import { mapGetters } from 'vuex'
import { uploadImage } from '@/api/upload'
import { snakeCase } from 'lodash/lodash.min'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import { getEstimateTime } from '@/api/shipping'
import { formatPrice, nl2br, getChunkedItems, formatDate, cutText } from '@/utils/helper'
import JvMessageBox from '@/components/Globals/JvMessageBox'
import { checkDocVersion, getMenuBubbleCounter } from '@/api/helper'

export default {
  components: {
    JvMessageBox,
  },
  data() {
    return {
      editorConfig: {
        plugins: 'wordcount code link paste lists image anchor table hr media',
        toolbar:
          'undo redo | styleselect | bold italic underline strikethrough | forecolor backcolor | link | alignleft aligncenter alignright | numlist bullist | outdent indent | code | image | anchor | media | table | hr',
        height: 400,
        skin: 'silver',
        // extended_valid_elements: "slot[name],cms-block[type|data|id|name],wr-render-block[type|data]",
        // valid_children: "a[div|p|img|span]",
        force_br_newline: false,
        force_p_newline: false,
        convert_urls: false,
        cleanup_on_startup: false,
        trim_span_elements: false,
        verify_html: false,
        cleanup: false,
        valid_elements: '[]',
        valid_children: '[]',
        browser_spellcheck: true,
        contextmenu: 'table',
        images_file_types: 'jpg,svg,webp,png,jpeg,gif',
        image_advtab: true,
        table_row_advtab: true,
        toolbar_drawer: 'sliding',
        images_upload_handler: async (blobInfo, cbSuccess, cbFailure) => {
          let formData = new FormData()
          formData.append('file', blobInfo.blob())
          const { success, filesUploaded } = await uploadImage(formData, null, 'cms').then(({ data }) => data)
          if (success) {
            cbSuccess(filesUploaded[0].full_path)
          } else {
            cbFailure('Has an error')
          }
        },
        paste_preprocess : (ed, args) => {
            // remove iniline styles
            args.content = args.content.replace(/\s\w+="[^"]*"/g,'');
            args.content = args.content.replace(/<span>/gi, "");
            args.content = args.content.replace(/<\/span>/gi, "");
        },
        // paste_as_text: true,
      },
      /* TODO - Load currency info from Setting/API */
      currency: {
        symbol: '$',
        fractionDigits: 2,
        position: -1 /* -1: prepend, 1: append */,
      },
      optionsIcon: {
        'button': 'view-stacked',
        'radio': 'list-ul',
        'select': 'menu-button',
        'text': 'fonts',
        'textarea': 'textarea-t',
        'iconlist': 'grid',
        'saved': 'journal-bookmark-fill'
      },
      ESSENTIAL_FEATURE: {
        PURCHASE_ORDER: 'PURCHASE_ORDER',
        QUOTE: 'QUOTE',
        GIFTCERTIFICATE: 'GIFTCERTIFICATE',
      },
      JMC_FEATURE: {
        CUSTOMER_MANAGEMENT: 'CUSTOMER_MANAGEMENT',
        PRODUCT_REVIEWS: 'PRODUCT_REVIEWS',
        ORDER_CREATION: 'ORDER_CREATION'
      }
    }
  },
  methods: {
    getCDN(clientCode) {
      const cdn = window.HM_ENV.CDN_URL
      const _clientCode = clientCode || this.$store.getters['client']
      const clusterID = this.$store.getters['clusterID']
      if (cdn && _clientCode) {
        let url = `${cdn}/${_clientCode}`
        if (clusterID && !['production', 'prod'].includes(clusterID)) {
          url += `-${clusterID}`
        }
        return url
      }
      return '/'
    },
    canShowItem(item) {
      let canShow = false
      if (!item.isHide) {
        if (Array.isArray(item.clientType)) {
          canShow = item.clientType.includes(this.clientType)
        } else {
          canShow = !item.clientType || item.clientType === 'all' || item.clientType === this.clientType || (item.clientType == 'full' && this.isSuperClient)
        }

        if (canShow) {
          if (item.jmcFlag) {
            canShow = this.$store.getters.features[`JMC_${item.jmcFlag}`] != 'hide'
          }
          if (item.clientFeatureToggle) {
            canShow = this.$store.getters.features[item.clientFeatureToggle] == 'enabled'
          }
        }
      }
      return canShow
    },
    async getMenuBubbleCounter(source) {
      if (
        source === 'flyout-sidebar' 
        && ['sales_orders_management', 'sales_orders_detail'].includes(this.$route.name)
      ) {
        /*
          There is no need to request from flyout-sidebar
          because these components will automatically call when loading,
          early return to prevent duplicated requests      
        */
        return
      }
      const { data } = await getMenuBubbleCounter()
      if (data.success) {
        delete data.success
        this.$store.dispatch('setMenuBubbleCounter', data)
      }
    },
    hideFeatureByEssential(ESSENTIAL_FEATURE_FLAG_CODE) {
      return this.$checkClientType(['essential', 'marketplaceseller'], { isSuperClient: false }) &&
        (!ESSENTIAL_FEATURE_FLAG_CODE || this.$store.getters.features[`ESSENTIAL_${ESSENTIAL_FEATURE_FLAG_CODE}`] == 'hide')
    },
    hideFeatureByJMC(JMC_FEATURE_FLAG_CODE) {
      return this.$checkClientType(['marketplaceseller']) &&
        (!JMC_FEATURE_FLAG_CODE || this.$store.getters.features[`JMC_${JMC_FEATURE_FLAG_CODE}`] == 'hide')
    },
    _sleep(ms) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, ms)
      })
    },
    async checkDocVersion() {
      return await checkDocVersion({ docVersion: this.docVersion })
    },
    customerDisplay(customer) {
      if (typeof customer != 'object') {
        return ''
      }

      let customerDisplay = ''
      if (customer.company) {
        customerDisplay = customer.company || ''
      } else {
        const first_name = (customer.first_name || '').trim()
        const last_name = (customer.last_name || '').trim()
        if (first_name || last_name) {
          customerDisplay = first_name + ' ' + last_name
        } else if (customer.email) {
          customerDisplay = customer.email
        }
      }
      return customerDisplay
    },
    removeTimezoneOffset(date) {
      if (typeof date == 'string') {
        date = new Date(date)
      }
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000))
    },
    setToEndDate(date) {
      if (typeof date == 'string') {
        date = new Date(date)
      }
      return new Date(moment(date).endOf('day'))
    },
    getGroupName(id) {
      const groups = this.$store.state.app.customerGroups
      let index = groups.findIndex((e) => {
        return +e['_id'] === +id
      })
      if (index >= 0) {
        return groups[index].name
      } else {
        return ''
      }
    },
    getGroups(options = {}) {
      let groups = this.$store.state.app.customerGroups || []
      const { excludes = [] } = options
      if (Array.isArray(excludes) && excludes.length) {
        groups = groups.filter(group => !excludes.includes(group.code))
      }
      return groups
    },
    isMenuDisabled(path) {
      return Array.isArray(this.disabledMenus) && this.disabledMenus.includes(path)
    },
    isSettingMenuDisabled(path) {
      return Array.isArray(this.disabledSettingMenus) && this.disabledSettingMenus.includes(path)
    },
    acl(path) {
      if (this.userACL === '*') {
        return true
      }

      path = path.split('/')
      path = path
        .map((p) => {
          return snakeCase(p)
        })
        .join('/')
      return Array.isArray(this.userACL) && this.userACL.includes(path)
    },
    renderBoolean(bool, trueText = 'Yes', falseText = 'No') {
      // eslint-disable-next-line
      return Boolean(bool) ? trueText : falseText
    },
    ucfirst(str) {
      if (str && typeof str === 'string') {
        str = str[0].toUpperCase() + str.substr(1)
      }
      return str
    },
    hasModule(key) {
      return this.modules[key] || false
    },
    _handleAttributeNumberChange(value, precision, scale) {
      precision = precision || 18
      scale = scale || 0
      value = String(value)
      let [left, right] = value.split('.')
      left = left.replace(/^0+/gm, '')
      right = right || ''
      const rest = scale - right.length
      if (rest > 0) {
        right += '0'.repeat(rest)
      } else if (rest < 0) {
        right = right.slice(0, rest)
      }
      if ([left, right].join('').length > precision) {
        const rest = precision - right.length
        if (rest < left.length) {
          left = left.slice(0, rest)
        }
        left = left.replace(/^0+/gm, '')
      }
      let result = [left, right].join('.')
      result = result.endsWith('.') ? result.slice(0, -1) : result
      result = result.startsWith('.') ? '0' + result : result
      return result
    },
    formatAttributeNumberType(value, precision = 18, scale = 0) {
      value = BigNumber(value)
      if (value.isNaN()) {
        return ''
      } else {
        value = value.toFixed(scale)
        if (value.includes('e')) {
          return Number('1.' + '0'.repeat(scale))
        }
        let [left, right] = value.split('.')
        right = right || ''
        left = left.slice(0, precision - scale)
        return left + (right ? '.' + right : '')
      }
    },
    formatCost(value) {
      let attrCost = this.$store.getters['attributeInfo'].find(attr => attr.code === 'cost')
      if (!attrCost) {
        attrCost = { number_precision: 18, number_scale: 4 }
      }
      return this.formatAttributeNumberType(value, attrCost.number_precision, attrCost.number_scale)
    },
    formatCode(text) {
      let x = text.replace(/[\W_]/g, '').match(/([\w_]{0,4})([\w_]{0,6})([\w_]{0,5})/)
      return (!x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')).toUpperCase()
    },
    async formatDeliveryEstimateTime(processing_time, transit_time) {
      let timeData = ''
      if (
        processing_time &&
        transit_time &&
        processing_time.from &&
        processing_time.to &&
        transit_time.from &&
        transit_time.to
      ) {
        const params = {
          timezone_offset: moment().utcOffset(),
          processing_time,
          transit_time,
        }
        this.isLoading = true
        const result = await getEstimateTime(params).catch(() => false)
        if (result.data && result.data.success) {
          timeData = result.data.deliveryEstimate
        }
      }
      return timeData
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0'
      const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]}`
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    },
    _getNegativeOperator(number, isNegative) {
      if (isNegative && Number(number) > 0) {
        return '-'
      } else {
        return ''
      }
    },
    entityClassOptions(entity_code = 'product', isAll = true) {
      const classes = []
      if (isAll) {
        classes.push({ key: '', label: 'All' })
      }
      const classMap = this.entityClasses(entity_code)
      Object.keys(classMap).forEach((key) => {
        classes.push({ key, label: classMap[key] })
      })
      return classes
    },
    entityClasses(entity_code = 'product') {
      const classMap = this.$store.getters['entityClasses']
      return (classMap && classMap[entity_code]) || {}
    },
    notificationEvents(type) {
      const classMap = this.$store.getters['notificationEvents'] || {}
      return (classMap && type && classMap[type]) || classMap
    },
    /** Compact long text by collapse the middle into eclipse */
    compactText(string, threshold = 35, startLen = 15, trailingLen = 15, separator = '...') {
      if (string && string.length >= threshold) {
        const firstPart = string.slice(0, startLen)
        const trailingPart = trailingLen ? string.slice(-trailingLen) : ''
        return `${firstPart}${separator}${trailingPart}`
      }
      return string
    },
    getProductClientCode(productId, clientGuid) {
      for (const code of Object.keys(this.$store.getters['client_products'] || [])) {
        if (this.$store.getters['client_products'][code].includes(productId)) {
          return code
        }
      }
      return this.$store.getters['client_guids'] ? this.$store.getters['client_guids'][clientGuid] || '' : ''
    },
    productImageURL(product, image = null, size = '') {
      if (image) {
        if (typeof image === 'string' && image.startsWith('http')) {
          return image
        }
        let productId = product._id || product.id || product
        let url = `${this.CDN}/images/products/`
        if (this.$checkClientType('marketplace')) {
          url = `${this.getCDN(this.getProductClientCode(productId, product.client_guid))}/images/products/`
        }
        if (image && image.includes('|')) {
          let [product_id, name] = image.split('|')
          productId = product_id
          image = name || 'undefined'
        }
        url += `${productId}/`
        if (size) {
          url += `${size}/`
        }
        return url + image
      }
      return this.placeholderImage
    },
    matchPriceLevel(priceLevels, conditions) {
      let customerGroup = 0
      if (conditions.customerGroup) {
        customerGroup = conditions.customerGroup
      }

      let matchedPriceLevel = null
      if (priceLevels && Array.isArray(priceLevels)) {
        priceLevels.forEach(pl => {
          if (pl.customer_groups.includes(customerGroup) || !pl.customer_groups.length) {
            if (conditions.qty >= pl.min_qty) {
              if (!matchedPriceLevel) {
                matchedPriceLevel = pl
              } else {
                if (+matchedPriceLevel.price > +pl.price) {
                  matchedPriceLevel = pl
                }
              }
            }
          }
        })
      }

      return matchedPriceLevel
    },
    formatPrice,
    nl2br,
    getChunkedItems,
    formatDate,
    cutText,
    isValidUrl(urlString) {
      // eslint-disable-next-line
      const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
      return regex.test(urlString)
    },
    randomId() {
      return Date.now() + Math.round(Math.random() * 10000)
    }
  },
  computed: {
    ...mapGetters({
      isSuperClient: 'client/isSuperClient',
    }),
    isAgeLimit() {
      return this.client?.industry === 'tobacco'
    },
    client() {
      return this.$store.getters['client/client']
    },
    clientType() {
      return this.client?.type || 'full'
    },
    isLimitedClient() {
      return this.$checkClientType(['essential', 'marketplaceseller'])
    },
    docVersion() {
      if (this.versionObject) {
        const { doc, docType } = this.versionObject
        if (!doc || !docType || !this[doc]) {
          return null
        }
        return { _id: this[doc]._id, updatedAt: this[doc].updatedAt, docType }
      } else {
        return null
      }
    },
    userACL() {
      return this.$store.getters['acl']
    },
    disabledMenus() {
      return this.$store.getters['disabledMenus']
    },
    disabledSettingMenus() {
      return this.$store.getters['disabledSettingMenus']
    },
    modules() {
      return this.$store.getters['modules']
    },
    isNewTheme() {
      return this.$store.getters['isNewTheme'] || window.HM_ENV.THEME === 'v2'
    },
    CDN() {
      return this.getCDN()
    },
    placeholderImage() {
      const placeholderImageName = this.$store.getters['placeholderImage']
      if (placeholderImageName) {
        if (placeholderImageName.startsWith('http') || placeholderImageName.includes('?HM_IMAGE_URL')) {
          return placeholderImageName
        } else {
          return `${this.getCDN(this.client.marketplace || this.client.code)}/images/products/${placeholderImageName}`
        }
      }
      return `${window.HM_ENV.CDN_URL}/Jovy/media/images/placeholder.svg`
    },
    shopSetupCount() {
      return Object.keys(this.$store.state.app.shop_setup || {}).length
    },
    shopSetupCompletedCount() {
      return Object.keys(this.$store.state.app.shop_setup || {}).filter(key => this.$store.state.app.shop_setup[key]).length
    },
    isAllShopSetupCompleted() {
      return this.shopSetupCompletedCount === this.shopSetupCount
    }
  },
}
