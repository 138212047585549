<template>
  <modal-widget
    title="Select Client"
    :visible-sync="visible"
    dialogClass="dialog-md"
    @closeModal="closeModal"
  >
    <template slot="content">
      <div v-for="(client, index) in clients" :key="`client-${client.code}`" :class="{ 'mt-2': index > 0 }">
        <jv-button
          :disabled="client.is_active === false || client === currentClient"
          native-type="button"
          class="d-block w-100 text-left"
          @click="handleSelectClient(client)"
        >
          <div class="d-flex align-items-center gap-8">
            <span class="text-dark fs-15">{{ client.name }}</span>
            <small class="text-secondary">{{ client.code }}</small>
            <i v-if="isSelectededClient(client)" class="bi bi-check2-circle text-success fs-18 ml-2"></i>
            <span v-if="client.is_active === false">(Not available to access)</span>
          </div>
        </jv-button>
      </div>
    </template>
    <div slot="footer" class="dialog-footer">
      <jv-button @click="closeModal">Cancel</jv-button>
    </div>
  </modal-widget>
</template>

<script>
export default {
  name: 'SelectClientModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedClient: '',
    }
  },
  computed: {
    clients() {
      const clients = this.$store.getters.clients || []
      return [...clients].sort((a, b) => a.name.localeCompare(b.name))
    },
    currentClient() {
      const clientCode = this.$store.getters.client
      return clientCode && this.clients.find(client => client.code === clientCode)
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false)
    },
    handleSelectClient(client) {
      if (!this.selectedClient) {
        this.selectedClient = client.code
        this.$emit('selected', client)
        setTimeout(() => {
          this.closeModal()
        }, 100)
      }
    },
    isSelectededClient(client) {
      if (this.selectedClient) {
        return client.code === this.selectedClient
      }
      return client === this.currentClient
    },
  },
};
</script>
