<template>
  <div class="wr-wrapper-content">
    <div ref="header" class="header__fixed">
      <div class="header-content d-flex justify-content-between align-items-center" :class="{'header-mobile': $isMobile, 'success': success}">
        <div class="h-button">
          <jv-button v-if="backButton && !backButton.key" size="medium" :type="backButton.type" :plain="backButton.plain"
            icon="bi bi-chevron-bar-left icon"
            @click="doAction(backButton)" :disabled="backButton.disabled">
            <template v-if="!backButton.isHideLabel">{{ backButton.label }}</template>
            <template v-if="backButton.icon && backButton.iconPosition === 'right'"><i
                :class="backButton.icon + (!backButton.isHideLabel ? '  ml-2' : '')"></i></template>
          </jv-button>
        </div>
        <div v-if="success" class="success-message-container">
          <i class="bi bi-check2-circle"/>
          <span class="success-message">Changes Saved</span>
        </div>
        <div class="logo mr-lg-auto">
          <div class="d-none d-lg-block">
            <!-- <h4>{{ pageTitle }}</h4> -->
            <old-header-left ref="old-header-left" class="ml-3"></old-header-left>
          </div>
          <div class="d-block d-lg-none">
            <img src="@/assets/images/jovy-logo-white.svg" alt="logo" @click="openClientsSelector" />
          </div>
        </div>
        <div class="h-button">
          <account-info class="d-none d-lg-block"></account-info>
          <i class="d-block d-lg-none fa fa-bars h-icon text-right" @click="drawer = true"></i>
        </div>
      </div>
      <div v-if="subscriptionAlert" class="subscription-alert">
        <span>{{ subscriptionAlert }}&nbsp;</span>
        <a v-if="isOwnerUser" :href="manageSubscriptionUrl" target="_blank">Manage Subscription</a>
      </div>
    </div>
    <div class="content-page" :class="{ 'show-alert': subscriptionAlert }">
      <div class="justify-content-end mb-2 d-none d-lg-flex" v-if="toolbarWidges && toolbarWidges.length"><toolbar-buttons :buttons="toolbarWidges"></toolbar-buttons></div>
      <router-view :key="reRender" class="px-2 px-lg-0"></router-view>
    </div>
    <el-drawer :visible.sync="drawer" :show-close="false" custom-class="background-drawer" :withHeader="false" direction="rtl" size="260px">
      <!-- <jv-button class="close-drawer" icon="el-icon-close" @click="drawer = false"></jv-button> -->
      <flyout-sidebar />
    </el-drawer>
  </div>
</template>

<script>
import FlyoutSidebar from './FlyoutSidebar.vue';
import OldHeaderLeft from './Header/OldHeaderLeft.vue';
// import SideBar from './SideBar.vue'

export default {
  components: {
    // SideBar,
    FlyoutSidebar,
    AccountInfo: () => import('./Header/AccountInfo.vue'),
    OldHeaderLeft,
},
  data() {
    return {
      drawer: false,
      originalTop: 0,
      isFixed: false
    };
  },
  methods: {
    dismissAlert: function (index) {
      this.$store.dispatch("dismissAlert", index);
    },
    doAction(button) {
      if (button.callback && typeof button.callback == "function") {
        button.callback();
      }
      else if (button.route) {
        let route = { name: button.route };
        if (button.routeParams) {
          route.params = button.routeParams;
        }
        this.$router.push(route);
      }
      else if (button.path) {
        this.$router.push(button.path);
      }
      else {
        console.warn(`Button "${button.label}" has an invalid action`);
      }
    },
    handleMoreAction(btn) {
      this.doAction(btn);
    },
    handleWindows: function () {
      this.isFixed = Math.round(window.scrollY) > 0;
    },
    openClientsSelector() {
      if (this.$refs && this.$refs['old-header-left']) {
        if (this.$store.getters.clients && this.$store.getters.clients.length > 1) {
          this.$refs['old-header-left'].showSelectClientDialog = true
        }
      }
    }
  },
  mounted() {
    const el = this.$refs.header;
    if (el) {
      this.originalTop = +el.getBoundingClientRect().top + +el.getBoundingClientRect().height;
    }
    window.addEventListener("scroll", this.handleWindows)
  },
  watch: {
    $route: function () {
      this.$store.commit('SET_SUCCESS', false)
    }
  },
  computed: {
    alerts: function () {
      return this.$store.getters.alerts;
    },
    loadingStatus: function () {
      return this.$store.getters.loadingStatus;
    },
    reRender: function () {
      return Number(this.$store.getters.reRender);
    },
    pageTitle() {
      return this.$store.getters.pageTitle;
    },
    toolbarWidges() {
      return (this.$store.getters.toolbarWidges || []).filter(f => f.label !== "Back");
    },
    backButton() {
      if (this.$router?.history?.current?.path?.includes('/settings/')) {
        return { label: 'Back', icon: 'el-icon-arrow-left', callback: () => { this.$router.back() } }
      }
      return (this.$store.getters.toolbarWidges || []).find(f => f.label === "Back");
    },
    subtitle() {
      return this.$store.getters.subtitle || "";
    },
    success() {
      return this.$store.getters.success;
    },
    user() {
      return this.$store.getters.user
    },
    isOwnerUser() {
      return this.user && this.user._id === this.client.owner
    },
    subscriptionAlert() {
      const subscription = this.client.subscription
      if (subscription) {
        if (subscription.status === 'past_due') {
          return 'Your subscription is past due. Please take immediate action to avoid account closure. Your website will be unavailable until resolved.'
        }
        if (subscription.status === 'paused') {
          return 'Your subscription has been paused. Your website will be unavailable until your subscription is resumed.'
        }
      }
    },
    manageSubscriptionUrl() {
      let url = HM_ENV.STRIPE_CUSTOMER_PORTAL
      if (this.isOwnerUser) {
        url += `?prefilled_email=${encodeURIComponent(this.user.email)}`
      }
      return url
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleWindows);
  },
}
</script>

<style lang="scss" scoped>
.content-title {
  color: $primary;
}
.header-content {
  padding: 0 20px 0 12px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  background: var(--jv-brand-500);
  margin-bottom: 0;
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  height: $jv-header-height;
  @media (min-width: $lg) {
    border-radius: 4px;
    top: 8px;
  }
  .h-icon, button {
    color: white;
  }
  button {
    border-color: white;
    background: transparent;
    padding: 7px 12px;
    font-size: 14px;
    display: flex;
    :deep(.icon) {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
.h-icon {
  color: $default;
  font-size: 24px;
}
.header-mobile {
  padding: 12px 20px;
  width: 100%;
  .h-button {
    min-width: 100px;
  }
}
.header__fixed {
  position: fixed;
  top: 0;
  background: #F0F0F0;
  z-index: 1010;
  right: 0;
  left: 0;
  @media (min-width: $lg) {
    left: 216px;
    padding-bottom: 8px;
    padding-top: 8px;
    right: 8px;
  }
  
  .subscription-alert {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--jv-red-400);
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    height: 30px;
    padding: 3px 12px;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 1px 1px 0px #00000026;
    margin-top: -4px;
    z-index: 1;
    @media (min-width: $lg) {
      height: 26px;
    }
    
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
.logo {
  max-height: 36px;
  img {
    object-fit: contain;
    max-width: 100%;
    height: 36px;
  }
}
.close-drawer {
  position: fixed;
  z-index: 3031;
  top: 12px;
  right: 20px;
  font-size: 20px;
  background: transparent;
  padding: 4px;
  color: white;
}
.content-page {
  padding-top: 64px;
  @media (min-width: $md) {
    padding-top: 68px;
  }
  &.show-alert{
    padding-top: 94px;
    @media (min-width: $lg) {
      padding-top: 90px;
    }
  }
}

.success {
  background-color: var(--jv-green-400);
}

.success-message-container {
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.success-message {
  margin-left: 4px
}

.bi-check2-circle:before {
  font-size: 23px;
  height: 23px;
  width: 24px;
}

</style>
