export default {
  pageTitle: state => state.app.pageTitle,
  currentSidebar: state => state.app.currentSidebar,
  currentHeader: state => state.app.currentHeader,
  currentContent: state => state.app.currentContent,
  sidebarHeight: state => state.app.sidebarHeight,
  token: state => state.user.token,
  user: state => state.user.user,
  renewTokenTogle: state => state.user.renewTokenTogle,
  alerts: state => state.app.alerts,
  loadingStatus: state => state.app.loadingStatus,
  confirmModalItems: state => state.app.confirmModalItems,
  reRender: state => state.app.reRender,
  mapCustomerGroup: state => state.app.mapCustomerGroup,
  lastRoute: state => state.app.lastRoute,
  routeHistory: state => state.app.routeHistory,
  countries: state => state.app.countries,
  stateRequired: state => state.app.stateRequired,
  isLocked: state => state.user.isLocked,
  websites: state => state.app.websites,
  websiteId: state => state.app.websiteId,
  frequencies: state => state.app.frequencies,
  acl: state => state.app.acl,
  googleReCaptchaSiteKey: state => state.app.googleReCaptchaSiteKey,
  settings: state => state.app.settings,
  blocks: state => state.app.cmsBlocks || {},
  toolbarWidges: state => state.app.toolbarWidges,
  subtitle: state => state.app.subtitle,
  modules: state => state.app.modules || {},
  locale: state => state.app.locale,
  clients: state => state.user.clients,
  client: state => state.user.client,
  attributeInfo: state => state.app.attributeInfo,
  systemName: state => state.app.systemName,
  baseUrl: state => state.app.base_url,
  templateBuilderURL: state => state.app.template_builder_url,
  orderStates: state => state.app.orderStates,
  invoiceStatuses: state => state.app.invoiceStatuses,
  deliveryStatuses: state => state.app.deliveryStatuses,
  claimStatuses: state => state.app.claimStatuses,
  entities: state => state.app.entities,
  entityClasses: state => {
    const map = {}
    const entities = state.app.entities
    for (const code of Object.keys(entities)) {
      map[code] = entities[code].classes
    }
    return map
  },
  paymentEvents: state => state.app.paymentEvents,
  notificationEvents: state => state.app.notificationEvents,
  breadcrumb: state => state.app.breadcrumb,
  isNewTheme: state => state.app.isNewTheme,
  deliveryResetOptions: state => state.app.deliveryResetOptions,
  orderTags: state => state.app.orderTags,
  clusterID: state => state.app.cluster_id,
  disabledMenus: state => state.app.disabled_menus,
  disabledSettingMenus: state => state.app.disabled_setting_menus,
  placeholderImage: state => state.app.placeholder_image,
  rightSidebarSelected: state => state.app.rightSidebarSelected,
  features: state => state.app.features,
  paymentProvidersMap: state => state.app.paymentProvidersMap,
  guid: state => state.app.guid,
  success: state => state.app.success,
  parentCategory: state => state.app.parentCategory,
  mappedAttributes: state => state.app.mappedAttributes,
  QBO_middleware_client_ID:  state => state.app.QBO_middleware_client_ID,
  QBO_central_callback_URL: state => state.app.QBO_central_callback_URL,
  restrictedRoutes: state => state.app.restrictedRoutes,
  isShowSettingSidebar: state => state.app.isShowSettingSidebar,
  menuBubbleCounter: state => state.app.menuBubbleCounter,
  client_products: state => state.app.client_products,
  client_guids: state => state.app.client_guids,
}
